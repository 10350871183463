
import { ref, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { ElMessage } from "element-plus";
import AMap from "AMap"; // 引入高德地图
export default defineComponent({
  name: "news",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let formRef = ref(null)
    let form = ref({
      name: "",
      email: "",
      tel: "",
      desc: "",
    });

    function init() {
      var marker,
        map = new AMap.Map("container", {
          resizeEnable: true,
          center: [113.683551, 34.78596],
          zoom: 16,
        });

      marker = new AMap.Marker({
        position: [113.683551, 34.78596], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      marker.setMap(map);

      var content = [
        `<h3 style="color:#1989fa">河南省豫建建筑工程技术服务中心</h3>
        <div>电话:0371-86541037 13103832189</div>
        <div style="width: max-content;">地址:河南省-郑州市-金水区 豫博大厦东塔909</div>`,
      ];

      // 创建 infoWindow 实例
      var infoWindow = new AMap.InfoWindow({
        content: content.join("<br>"), //传入 dom 对象，或者 html 字符串
      });

      // 打开信息窗体
      infoWindow.open(map, map.getCenter());
    }

    function sub() {
      ElMessage.success("提交成功,感谢您的意见！");
      form.value = {
        name: "",
        email: "",
        tel: "",
        desc: "",
      };
    }

    onMounted(() => {
      init();
    });
    return {
      sub,
      form,
      formRef
    };
  },
});
